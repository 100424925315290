<template>
  <div class="form content">

    <section
      class="additional-content contained"
      v-if="activeQuestion.additionalContent">
      <article v-html="translate(`question-${activeQuestion.questionId}-additionalContent`, activeQuestion.additionalContent)"></article>
    </section>

    <section class="action-button-wrapper contained">
      <b-button
          block
          variant="secondary"
          class="leading"
          :disabled="loading"
          @click="continueToQuestions">
          <font-awesome-icon :icon="actionButtonIcon" />
          <span class="button-text">{{ translate(actionButtonText, actionButtonText) }}</span>
      </b-button>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Content',

  data: () => ({
    loading: false,
    actionButtonText: 'continue',
    actionButtonIcon: ['fas', 'arrow-right'],
  }),

  computed: {
    ...mapGetters('assessment', [
      'activeQuestion',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),
  },

  methods: {
    ...mapActions('assessment', [
      'addAnswer',
    ]),

    async continueToQuestions() {
      this.loading = true;
      await this.addAnswer({
        questionId: this.activeQuestion.questionId,
        questionType: this.activeQuestion.type,
        refDataId: 'continue',
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.questions {
  margin: 1.5rem 0;
}
</style>
