<template>
  <div class="form multiple-choice">
    <section class="questions">
      <section
        class="form-section"
        v-for="(question, questionIndex) in activeQuestion.subQuestions"
        :key="questionIndex">
        <div class="label">
          {{ translate(`question-${question.questionId}-label`, question.label) }}
        </div>

        <div class="form-buttons-section">
          <b-button
            variant="outline-info"
            v-for="(option, optionIndex) in optionsByQuestionId(activeQuestion.questionId)"
            :key="optionIndex"
            activeClass="selected"
            :disabled="loading"
            v-b-modal="`modal-${question.questionId}-${option.refDataId}`"
            :pressed="answerByQuestionIdAndRefDataId(question.questionId, option.refDataId) ? true : false"
            @click="answerSelected(question.questionId, option.refDataId)">
            {{ translate(`refData-${option.refDataId}-label`, option.label) }}
          </b-button>
        </div>

        <!-- The modals -->
        <b-modal
          v-for="(modal, modalIndex) in answerModalsByQuestionIds(question.questionId)"
          :key="`${question.questionId}${modalIndex}`"
          :id="`modal-${modal.questionId}-${modal.refDataId}`"
          :hideFooter="true">
          <template #modal-title>
            {{ translate(`answerContent-${modal.answerContentId}-contentTitle`, modal.contentTitle) }}
          </template>
          <div v-html="translate(`answerContent-${modal.answerContentId}-body`, modal.body)"></div>
        </b-modal>
      </section>
    </section>

    <section class="action-button-wrapper contained">
      <b-button
        block
        variant="secondary"
        class="leading"
        :disabled="loading || unansweredActiveSubQuestions.length !== 0"
        @click="continueToNext">
        <font-awesome-icon :icon="actionButton.icon" />
        <span class="button-text">{{ translate(actionButton.text, actionButton.text) }}</span>
      </b-button>
    </section>

    <section
      class="additional-content contained"
      v-if="activeQuestion.additionalContent">
      <article v-html="translate(`question-${activeQuestion.questionId}-additionalContent`, activeQuestion.additionalContent)"></article>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Table',

  data: () => ({
    loading: false,
    actionButton: {
      text: 'continue',
      icon: ['fas', 'arrow-right'],
    },
  }),

  computed: {
    ...mapGetters('assessment', [
      'activeQuestion',
      'optionsByQuestionId',
      'unansweredActiveSubQuestions',

      'answersByQuestionId',
      'answerByQuestionIdAndRefDataId',
      'answerModalsByQuestionIds',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),
  },

  methods: {
    ...mapActions('assessment', [
      'addAnswer',
    ]),

    async answerSelected(selectedQuestionId, selectedRefDataId) {
      await this.addAnswer({
        parentQuestionId: this.activeQuestion.questionId,
        questionId: selectedQuestionId,
        questionType: this.activeQuestion.type,
        refDataId: selectedRefDataId,
      });
    },

    async continueToNext() {
      this.loading = true;

      await this.addAnswer({
        questionId: this.activeQuestion.questionId,
        questionType: this.activeQuestion.type,
        refDataId: 'continue',
      });

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.questions {
  margin: 1.5rem 0;
}
</style>
