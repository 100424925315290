<template>
  <div class="form single-select contained">
    <section
      class="form-section"
      v-if="activeQuestion">
      <b-button
          block
          variant="outline-info"
          v-for="(option, optionIndex) in optionsByQuestionId(activeQuestion.questionId)"
          :key="optionIndex"
          activeClass="selected"
          :pressed="addingAnswer == option.refDataId ? true : false"
          @click="answerSelected(option.refDataId)">
          {{ translate(`refData-${option.refDataId}-label`, option.label) }}
      </b-button>
    </section>
    <section
      class="additional-content contained"
      v-if="activeQuestion.additionalContent">
      <article v-html="translate(`question-${activeQuestion.questionId}-additionalContent`, activeQuestion.additionalContent)"></article>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Single',

  data: () => ({
    loading: false,
    addingAnswer: null,
  }),

  computed: {
    ...mapGetters('assessment', [
      'activeQuestion',
      'optionsByQuestionId',

      'answerByQuestionIdAndRefDataId',
      'answerModalsByQuestionIds',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),
  },

  methods: {
    ...mapActions('assessment', [
      'addAnswer',
    ]),

    async answerSelected(selectedRefDataId) {
      this.loading = true;
      this.addingAnswer = selectedRefDataId;
      const activeQ = this.activeQuestion;

      await this.addAnswer({
        questionId: this.activeQuestion.questionId,
        questionType: this.activeQuestion.type,
        refDataId: selectedRefDataId,
      });
      await this.removeSelectedAnswer();
      await this.continueToNext();

      this.loading = false;
    },
    removeSelectedAnswer() {
      return new Promise((resolve) => setTimeout(function () {
        this.addingAnswer = null;
        resolve();
      }.bind(this), 100));
    },
    continueToNext() {
      return new Promise((resolve) => setTimeout(function () {
        this.addAnswer({
          questionId: this.activeQuestion.questionId,
          questionType: this.activeQuestion.type,
          refDataId: 'continue',
        });
        resolve();
      }.bind(this), 50));
    },
  },
};
</script>
