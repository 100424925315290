<template>
  <div class="form-layout">
    <b-jumbotron
      v-if="activeQuestion"
      class="page-header contained"
      :header="translate(`question-${activeQuestion.questionId}-label`, activeQuestion.label)"
      :lead="translate(`question-${activeQuestion.questionId}-intro`, activeQuestion.intro)"
      >
    </b-jumbotron>

    <component
      v-if="activeQuestion"
      :is="form"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { capitalize, each } from 'lodash';

const requireContext = require.context('@/components/questionTypes', false, /.*\.vue$/);
const forms = requireContext.keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file).default])
  .reduce((allForms, [name, form]) => {
    allForms[name] = form;
    return allForms;
  }, {});

export default {
  name: 'Questionaire',

  components: {
    ...forms,
  },

  data: () => ({
    testing: false,
    testData: [
      {
        questionId: 18,
        parentQuestionId: null,
        type: 'single',
        answers: [15, 'continue'],
      },
      {
        questionId: 21,
        parentQuestionId: null,
        type: 'single',
        answers: [2, 'continue'],
      },
      {
        questionId: 22,
        parentQuestionId: null,
        type: 'single',
        answers: [1, 'continue'],
      },
      {
        questionId: 23,
        parentQuestionId: null,
        type: 'single',
        answers: [27, 'continue'],
      },
      {
        questionId: 72,
        parentQuestionId: null,
        type: 'content',
        answers: ['continue'],
      },
      {
        questionId: 25,
        parentQuestionId: null,
        type: 'single',
        answers: [30, 'continue'],
      },
      {
        questionId: 29,
        parentQuestionId: null,
        type: 'single',
        answers: [1, 'continue'],
      },
      {
        questionId: 46,
        parentQuestionId: null,
        type: 'single',
        answers: [44, 'continue'],
      },
      {
        questionId: 26,
        parentQuestionId: null,
        type: 'single',
        answers: [31, 'continue'],
      },
      {
        questionId: 31,
        parentQuestionId: null,
        type: 'single',
        answers: [1, 'continue'],
      },
      {
        questionId: 80,
        parentQuestionId: null,
        type: 'single',
        answers: [2, 'continue'],
      },
      {
        questionId: 34,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 35,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 36,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 37,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 38,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 40,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 41,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 42,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 43,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 93,
        parentQuestionId: 44,
        type: 'table',
        answers: [61],
      },
      {
        questionId: 44,
        parentQuestionId: null,
        type: 'table',
        answers: ['continue'],
      },
      {
        questionId: 48,
        parentQuestionId: null,
        type: 'multi',
        answers: [66, 'continue'],
      },
      {
        questionId: 47,
        parentQuestionId: null,
        type: 'multi',
        answers: [69, 'continue'],
      },
    ],
  }),

  computed: {
    ...mapGetters('assessment', [
      'activeQuestion',
      'skipAllRules',
      'unansweredQuestions',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),

    form() {
      return this.activeQuestion ? capitalize(this.activeQuestion.type) : null;
    },
  },

  watch: {
    skipAllRules(newRules) {
      if (newRules && !this.activeQuestion) {
        this.$router.push('/recommendations');
      }
    },

    unansweredQuestions(values) {
      if (!values.length) {
        this.$router.push('/recommendations');
      }
    },
  },

  mounted() {
    if (this.testing) {
      each(this.testData, function (data) {
        each(data.answers, function (answer) {
          this.answerSelected(data.questionId, answer, data.type, data.parentQuestionId);
        }.bind(this));
      }.bind(this));
    }
  },

  methods: {
    ...mapActions('assessment', [
      'addAnswer',
    ]),

    async answerSelected(selectedQuestionId, selectedRefDataId, selectedType, selectedParentQuestionId = null) {
      this.loading = true;
      await this.addAnswer({
        questionId: selectedQuestionId,
        questionType: selectedType,
        parentQuestionId: selectedParentQuestionId,
        refDataId: selectedRefDataId,
      });
    },
  },
};
</script>
