<template>
  <div class="form single-select contained">
    <section
      class="form-section">
      <b-button
          block
          variant="outline-info"
          v-for="(option, optionIndex) in optionsByQuestionId(activeQuestion.questionId)"
          :key="optionIndex"
          activeClass="selected"
          :pressed="selectedAnswers.includes(option.refDataId) ? true : false"
          :disabled="loading"
          @click="answerSelected(option.refDataId)">
          {{ translate(`refData-${option.refDataId}-label`, option.label) }}
      </b-button>
    </section>

    <section class="action-button-wrapper">
      <b-button
        block
        variant="secondary"
        class="leading"
        :disabled="loading || !selectedAnswers.length"
        @click="saveSelectedAndContinue">
        <font-awesome-icon :icon="actionButtonIcon" />
        <span class="button-text">{{ translate(actionButtonText, actionButtonText) }}</span>
      </b-button>
    </section>

    <section
      class="additional-content contained"
      v-if="activeQuestion.additionalContent">
      <article v-html="translate(`question-${activeQuestion.questionId}-additionalContent`, activeQuestion.additionalContent)"></article>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  each,
  filter,
  find,
  findIndex,
  nth,
} from 'lodash';

export default {
  name: 'Multi',

  data: () => ({
    loading: false,
    actionButtonText: 'continue',
    actionButtonLink: 'continue',
    actionButtonIcon: ['fas', 'arrow-right'],
    selectedAnswers: [],
  }),

  computed: {
    ...mapGetters('assessment', [
      'activeQuestion',
      'optionsByQuestionId',

      'answersByQuestionId',
      'answerByQuestionIdAndRefDataId',
      'answerModalsByQuestionIds',
    ]),

    ...mapGetters('translations', [
      'translate',
    ]),
  },

  methods: {
    ...mapActions('assessment', [
      'addAnswer',
    ]),

    async answerSelected(selectedRefDataId) {
      if (this.selectedAnswers.length && find(this.selectedAnswers, function (answer) { return answer === selectedRefDataId; })) {
        this.selectedAnswers = filter(this.selectedAnswers, function (answer) {
          return answer !== selectedRefDataId;
        });
      } else {
        const lastOption = nth(this.activeQuestion.options, -1);
        if ((findIndex(this.activeQuestion.options, { refDataId: selectedRefDataId }) == this.activeQuestion.options.length - 1) || (selectedRefDataId !== lastOption.refDataId && findIndex(this.selectedAnswers, function (answer) {
          return answer === lastOption.refDataId;
        }) >= 0)) {
          this.selectedAnswers = [];
        }
        this.selectedAnswers.push(selectedRefDataId);
      }
    },

    async saveSelectedAndContinue() {
      this.loading = true;

      await each(this.selectedAnswers, function (answer) {
        this.addAnswer({
          questionId: this.activeQuestion.questionId,
          questionType: this.activeQuestion.type,
          refDataId: answer,
        });
      }.bind(this));

      this.selectedAnswers = [];
      await this.continueToNext();

      this.loading = false;
    },
    continueToNext() {
      return new Promise((resolve) => setTimeout(function () {
        this.addAnswer({
          questionId: this.activeQuestion.questionId,
          questionType: this.activeQuestion.type,
          refDataId: 'continue',
        });
        resolve();
      }.bind(this), 50));
    },
  },
};
</script>

<style lang="scss" scoped>
.action-button-wrapper {
  margin-top: 1rem;
}
</style>
